// import Logger from './common/util/logger'

// import dataDogInit from './metrics'
import React from 'react'
import { Helmet } from 'react-helmet'

import App from './App.jsx'
import './common/i18n'
import '@smartsheet/lodestar-core/dist/fonts/fonts.css'
import './common/common.css'
import favicon from './favicon.ico'

import { store } from './common/store/store'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

const AppWrapper = () => {
  // Error Handler
  // window.clientLogger = new Logger()
  // window.onerror = function (msg, url, lineNo, columnNo, error) {
  //   // Suppress unhelpful "Script Error" messages from IE 11 clients
  //   if (msg === 'Script Error' && !error) return
  //   //
  //   if (error) window.clientLogger.error(error.toString())
  //   else window.clientLogger.error(`"${msg}" (${lineNo}, ${columnNo})`)
  // }

  // // Setup DD Metrics
  // dataDogInit()

  // App
  // Render Main App component
  return (
    <Provider store={store}>
      <Helmet>
        <link rel='shortcut icon' href={favicon} />
      </Helmet>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>

  // document.getElementById('root')
  )
}

export default AppWrapper
